/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";
import "./Footer.scss";

function Footer() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <footer className="footer">
      <div>
        <Link type="link" onClick={handleClickOpen}>
          Legal Disclaimer
        </Link>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Legal Disclaimer</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              The information provided on gradfever
              website/communication/materials is for general informational
              purposes only. It is not intended to constitute legal, financial,
              or professional advice. Visitors/readers should not rely on the
              information as a substitute for seeking professional advice from a
              qualified attorney, accountant, or other relevant professional.
              While we strive to provide accurate and up-to-date information, we
              make no representations or warranties of any kind, express or
              implied, about the completeness, accuracy, reliability,
              suitability, or availability with respect to gradfever&apos;s
              website/communication/materials. Any reliance you place on such
              information is therefore strictly at your own risk. In no event
              will gradfever be liable for any loss or damage, including without
              limitation, indirect or consequential loss or damage, or any loss
              or damage whatsoever arising from loss of data or profits arising
              out of, or in connection with, the use of gradfever&apos;s
              website/communication/materials. Through gradfever&apos;s
              website/communication/materials, you may be able to link to other
              websites which are not under our control. We have no control over
              the nature, content, and availability of those sites. The
              inclusion of any links does not necessarily imply a recommendation
              or endorsement of the views expressed within them. Every effort is
              made to keep gradfever&apos;s website/communication/materials up
              and running smoothly. However, gradfever takes no responsibility
              for, and will not be liable for, the
              website/communication/materials being temporarily unavailable due
              to technical issues beyond our control. Remember, it&apos;s
              crucial to consult with a legal professional to ensure the
              disclaimer aligns with the specific requirements and regulations
              of your jurisdiction and business.
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </footer>
  );
}

export default Footer;
