import "./FoodHeader.scss";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ReactLogo } from "./logoText.svg";

function FoodHeader() {
  const navigate = useNavigate();
  return (
    <nav>
      <div className="nav-bar">
        <Container maxWidth="lg">
          <div className="nav-bar-inner">
            <ul className="nav-bar-left">
              <li>
                <span onClick={() => navigate("/")} aria-hidden="true">
                  <ReactLogo />
                </span>
              </li>
            </ul>
            <ul className="nav-bar-right">{/* <li>Dallas</li> */}</ul>
          </div>
        </Container>
      </div>
    </nav>
  );
}

export default FoodHeader;
