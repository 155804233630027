import React from "react";
// import Container from "@mui/material/Container";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import BackgroundAnimation from "../BackgroundAnimation";
import Header from "../Header/FoodHeader";

import LandingPage from "../LandingPageEamcet";
import Eamcet from "../Eamcet/Eamcet";
import CollegeRank from "../Eamcet/CollegeRank";
import Deals from "../Deals";

import "./App.scss";
import Footer from "../Footer";

function Layout({ children }) {
  return <div className="app-layout">{children}</div>;
}

export default function App() {
  return (
    <>
      <div className="App">
        <BrowserRouter>
          <Header />
          <Layout>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route
                path="/eamcet/counseling/2023/:statename"
                element={<Eamcet />}
              />
              <Route
                path="/college/ranking/2023/:statename"
                element={<CollegeRank />}
              />
              <Route path="/deals" element={<Deals />} />
            </Routes>
          </Layout>
        </BrowserRouter>
      </div>
      <Footer />
    </>
  );
}
