/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import { useEffect, useState, useRef } from "react";
import Container from "@mui/material/Container";
import Axios from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import { useMatch } from "react-router-dom";
import "./Eamcet.scss";

function Eamcet() {
  const match = useMatch("/eamcet/counseling/2023/:statename");
  const [dropDowns, setDropDowns] = useState({});
  const [searchResult, setSearchResult] = useState([]);
  const [inputError, setInputError] = useState(false);
  const [originalResults, setOrginalResults] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastIndex, setLastIndex] = useState(10);
  const [selectedDistrict, setSelectedDistrict] = useState([]);
  const rankRef = useRef(null);
  const departmentRef = useRef(null);
  const genderRef = useRef(null);
  const quotaRef = useRef(null);
  const districtRef = useRef(null);
  const placeRef = useRef(null);
  const baseUrl = "https://widgets-ahlnfowrya-uc.a.run.app/api";
  // const baseUrl = "http://localhost:3000/api";
  const pageMatch = match.params?.statename;
  const isAp = pageMatch === "ap";
  useEffect(() => {
    // Fetch all drop downs
    try {
      const getDropdowns = async () => {
        const dropDownsResp = await Axios.get(
          `${baseUrl}/eamcet/getDropDowns?pageMatch=${pageMatch}`
        );
        const dropDownData = dropDownsResp?.data?.data;
        if (dropDownData) {
          // localStorage.setItem("eamcet-dropdowns", dropDownData);
          setDropDowns(dropDownData);
          const { districtMap } = dropDownData;
          const districtsArray = [];
          const placeMap = {};
          districtMap.forEach((district) => {
            const distKey = Object.keys(district)[0];
            districtsArray.push(distKey);
            placeMap[distKey] = district[distKey];
          });
          setPlaces(placeMap);
          setDistricts(districtsArray);
        }
      };
      // if (!localStorage.getItem("eamcet-dropdowns")) {
      getDropdowns();
      // }
    } catch (err) {
      console.log(err);
    }
  }, []);
  const { departments, gender, quota } = dropDowns;

  const onSubmitHandler = async (e) => {
    try {
      e.preventDefault();
      const iRank = rankRef?.current?.value;
      const iDepartment = departmentRef?.current?.value;
      const iGender = genderRef?.current?.value;
      const iQuota = quotaRef?.current?.value;
      const iDistrict = districtRef?.current?.value;
      const iPlace = placeRef?.current?.value;
      const data = {
        rank: iRank,
        department: iDepartment,
        gender: iGender,
        quota: iQuota,
        district: iDistrict,
        place: iPlace,
      };
      if (iRank && iDepartment && iGender && iQuota) {
        setInputError(false);
        setLoading(true);
        const results = await Axios({
          url: `${baseUrl}/eamcet/getResults?pageMatch=${pageMatch}`,
          method: "post",
          data,
        });
        const sortedResults = results?.data?.data?.sort(
          (a, b) => a.rank - b.rank
        );
        let topResults = sortedResults;
        if (sortedResults.length > 10) {
          topResults = sortedResults.slice(0, 10);
        }
        setOrginalResults(sortedResults);
        setSearchResult(topResults);
      } else {
        setInputError(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleShowMore = () => {
    const originalList = originalResults;
    // current slice ??
    const newIndex = lastIndex + 10;
    const nextList = originalList.slice(0, newIndex);
    setLastIndex(newIndex);
    setSearchResult(nextList);
  };
  return (
    <div className="eamcet-main">
      <Container maxWidth="lg">
        <div className="heading">
          {isAp ? "Andhra Pradesh" : "Telangana"} EAMCET counseling predictor
          2023
        </div>
        <div className="search">
          <form onSubmit={onSubmitHandler} className="search-form">
            <div className="search-inline">
              <Box
                sx={{
                  width: "30%",
                  maxWidth: "100%",
                }}
              >
                <TextField
                  fullWidth
                  id="outlined-basic"
                  placeholder="Enter your rank"
                  type="number"
                  variant="outlined"
                  inputRef={rankRef}
                  label="Rank"
                />
              </Box>

              <Box sx={{ width: "60%" }}>
                <FormControl fullWidth>
                  <InputLabel id="select-branch">Branch</InputLabel>
                  <Select
                    labelId="select-branch"
                    id="select-id-branch"
                    label="Branch"
                    inputProps={{ ref: departmentRef }}
                  >
                    {departments?.map((dept) => (
                      <MenuItem
                        value={dept.BRANCH}
                        className="menu-item-dropdown"
                      >
                        {dept.BRANCH_NAME || dept.BRANCH}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="search-inline">
              <Box sx={{ width: "20%" }}>
                <FormControl fullWidth>
                  <InputLabel id="select-gender">Gender</InputLabel>
                  <Select
                    labelId="select-gender"
                    id="select-id-gender"
                    label="Gender"
                    inputProps={{ ref: genderRef }}
                  >
                    {gender?.map((each) => (
                      <MenuItem
                        value={each.value}
                        className="menu-item-dropdown"
                      >
                        {each.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ width: "20%" }}>
                <FormControl fullWidth>
                  <InputLabel id="select-category">Category</InputLabel>
                  <Select
                    labelId="select-category"
                    id="select-id-category"
                    label="Category"
                    inputProps={{ ref: quotaRef }}
                    className="menu-item-dropdown"
                  >
                    {quota?.map((each) => (
                      <MenuItem value={each.value}>{each.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ width: "20%" }}>
                <FormControl fullWidth>
                  <InputLabel id="select-category">District</InputLabel>
                  <Select
                    labelId="select-category"
                    id="select-id-category"
                    label="Category"
                    inputProps={{ ref: districtRef }}
                    className="menu-item-dropdown"
                    onChange={(e) => {
                      setSelectedDistrict(e.target.value);
                      placeRef.current = null;
                    }}
                  >
                    {districts?.map((each) => (
                      <MenuItem value={each}>{each}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ width: "20%" }}>
                <FormControl fullWidth>
                  <InputLabel id="select-category">Place</InputLabel>
                  <Select
                    labelId="select-category"
                    id="select-id-category"
                    label="Category"
                    inputProps={{ ref: placeRef }}
                    className="menu-item-dropdown"
                  >
                    {places?.[selectedDistrict]?.map((each) => (
                      <MenuItem value={each}>{each}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <Button variant="contained" type="submit" className="search-btn">
              Search
            </Button>
            <div className="input-error">
              {inputError && <span>Please enter all fields</span>}
            </div>
          </form>
        </div>
        <div className="result">
          <div className="result-header">
            <div className="result-info-code align-center">INST CODE</div>
            <div className="result-info-name align-center">INSTITUTE NAME</div>
            <div className="result-info-place align-center hide">PLACE</div>
            <div className="result-info-fee align-center hide">
              TUTION FEE (&#8377;)
            </div>
            <div className="result-info-rank align-center">LAST RANK</div>
          </div>
          <hr />
          {loading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : searchResult?.length === 0 ? (
            <div className="no-data">No Data</div>
          ) : (
            <div>
              {searchResult.map((res, index) => {
                const {
                  INSTITUTE_NAME,
                  // BRANCH,
                  rank,
                  // COED,
                  // AFFILIATED,
                  // DIST,
                  INST_CODE,
                  PLACE,
                  TUITION_FEE,
                  INST_REG,
                  // TYPE,
                  // YEAR_OF_ESTB,
                } = res;
                return (
                  <Card sx={{ minWidth: 275, minHeight: 50 }}>
                    <div className="result-info">
                      <div className="result-info-code">
                        <span>{index + 1}</span>. {INST_CODE}
                      </div>
                      <div className="result-info-name">{INSTITUTE_NAME}</div>
                      {/* <div className="result-info-1">{TYPE}</div> */}
                      <div className="result-info-place hide">{PLACE}</div>
                      <div className="result-info-fee hide">{TUITION_FEE}</div>
                      <div className="result-info-rank"> {rank}</div>
                    </div>
                    <div className="result-info-mobile">
                      <div className="wrapper-place-fee">
                        <div>
                          <span className="result-header-inner">PLACE:</span>
                          {PLACE}
                        </div>
                        <div>
                          <span className="result-header-inner">
                            TUTION FEE:
                          </span>
                          Rs.{TUITION_FEE}/-
                        </div>
                      </div>
                      {isAp && (
                        <div>
                          <span className="result-header-inner">REG:</span>{" "}
                          {INST_REG}
                        </div>
                      )}
                    </div>
                  </Card>
                );
              })}
              {searchResult.length === originalResults.length ? (
                <span />
              ) : (
                <Link type="link" onClick={handleShowMore}>
                  show more
                </Link>
              )}
            </div>
          )}
        </div>
      </Container>
    </div>
  );
}

export default Eamcet;
