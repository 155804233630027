import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import App from "./components/App/App";
import reportWebVitals from "./reportWebVitals";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const firebaseConfig = {
  apiKey: "AIzaSyA1g8puOL1C1Bfvq9oegNN2vJ4-eiGK6us",
  authDomain: "desifood-369e2.firebaseapp.com",
  projectId: "desifood-369e2",
  storageBucket: "desifood-369e2.appspot.com",
  messagingSenderId: "635289721896",
  appId: "1:635289721896:web:f91cdfc126c7b22c85a91d",
  measurementId: "G-N3TL8RS46R",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics and get a reference to the service
getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
