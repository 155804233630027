import { useEffect, useState } from "react";
import "./CollegeRank.scss";
import Axios from "axios";
import { useMatch } from "react-router-dom";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

const baseUrl = "https://widgets-ahlnfowrya-uc.a.run.app/api/eamcet";
// const baseUrl = "http://localhost:3000/api/eamcet";

function CollegeRank() {
  const match = useMatch("/college/ranking/2023/:statename");
  const pageMatch = match.params?.statename;
  const isAp = pageMatch === "ap";

  //   const isAp = pageMatch === "ap";
  const [rankingList, setRankingList] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getRankings = async () => {
      setLoading(true);
      const resp = await Axios.get(
        `${baseUrl}/getTopColleges?pageMatch=${pageMatch}`
      );
      setRankingList(resp?.data?.data);
      setLoading(false);
    };
    getRankings();
  }, []);
  return (
    <div className="college-ranking">
      <Container maxWidth="lg">
        <div className="heading">
          Top 100 Engineering colleges in{" "}
          {isAp ? "Andhra Pradesh" : "Telangana"} 2023
        </div>
        <div className="body">
          {loading ? (
            <Box sx={{ width: 300 }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
          ) : (
            <List>
              {rankingList.map((college, index) => (
                <ListItem disablePadding>
                  <ListItemText
                    primary={`${index + 1}.${college.INSTITUTE_NAME}`}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </div>
      </Container>
    </div>
  );
}

export default CollegeRank;
