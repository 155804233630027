/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import { useNavigate, Link } from "react-router-dom";
// import Link from "@mui/material/Link";
import "./LandingPage.scss";
import backgroundImage from "./backgroun_landing.jpg";

export default function LandingPage() {
  const navigate = useNavigate();
  const onClickHandler = (app) => {
    if (app === "TS") {
      navigate("/eamcet/counseling/2023/ts");
    } else {
      navigate("/eamcet/counseling/2023/ap");
    }
  };
  return (
    <div className="landing-page">
      <div
        className="landing-info"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "contain",
        }}
      >
        <div className="heading-text">
          Welcome to EAMCET counseling predictor 2023
        </div>
      </div>

      <Container>
        <div className="eamcet-landing">
          <Box
            sx={{
              height: 100,
            }}
            onClick={() => onClickHandler("AP")}
          >
            <Card
              sx={{ height: "100%", width: "100%", backgroundColor: "#acd4f7" }}
              className="landing-menu"
            >
              AP EAMCET
            </Card>
          </Box>
          <Box
            sx={{
              height: 100,
            }}
          >
            <Card
              sx={{ height: "100%", width: "100%", backgroundColor: "#acd4f7" }}
              className="landing-menu"
              onClick={() => onClickHandler("TS")}
            >
              TS EAMCET
            </Card>
          </Box>
        </div>
        <div className="college-links">
          <Link to="/college/ranking/2023/ap">
            Top Engineering colleges in Andhra Pradesh 2023
          </Link>
          <Link to="/college/ranking/2023/ts">
            Top Engineering colleges in Telangana 2023
          </Link>
        </div>
      </Container>
    </div>
  );
}
